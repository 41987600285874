import React from "react";

//header
import Header from "../../components/partials/dashboard/HeaderStyle/header";

//footer
import Footer from "../../components/partials/dashboard/FooterStyle/footer";

import { Outlet } from "react-router-dom";

const Default = () => {
  return (
    <>
      <div className="main-content">
        <div className="position-relative">
        <Header />
        {/* <div id="content-page" className="content-inner"> */}
        {/* <DefaultRouter/> */}
        <Outlet />
        {/* </div> */}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default Default;
